<template>
  <v-card class="mb-5">
    <v-dialog v-model="addNewModal" width="80%">
      <AdminCreateGroup />
    </v-dialog>

    <v-sheet class="d-flex mb-0">
      <v-sheet class="mr-auto">
        <v-card-title> Organisations List </v-card-title>
        <v-card-text> Here is the list of all active organisations that you manage. </v-card-text>
      </v-sheet>

      <v-sheet class="pa-4">
        <v-btn v-if="User.isSuperAdmin" color="primary" @click="addNewModal = true">
          <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
          &nbsp; NEW ORGANISATION
        </v-btn>
      </v-sheet>
    </v-sheet>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="groupList"
      item-key="groupUuid"
      class="table-rounded"
      show-select
      :items-per-page="30"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 30, 50, 100, -1],
      }"
    >
      <template #[`item.groupName`]="{ item }">
        <router-link :to="{ name: 'admin-group', params: { groupUuid: item.groupUuid } }">
          {{ item.groupName }}
        </router-link>
      </template>
      <template #[`item.actions`]="{}">
        <div class="mt-1">
          <!-- <v-btn
            color="info"
            small
            class="mr-1 mb-1"
            @click="xxx(item.groupUuid)"
          >
            <v-icon :size="16">{{ icons.mdiDelete }}</v-icon>
            &nbsp; DO SOMETHING
          </v-btn> -->
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import escapeRegExp from '@/utils/escapeRegExp'
import onUserLoaded from '@/utils/onUserLoaded'
import EventBus from '@/classes/EventBus'
import Groups from '@/classes/Groups'
import User from '@/classes/User'
import AdminCreateGroup from '@/views/admin/AdminCreateGroup.vue'

export default {
  components: {
    AdminCreateGroup,
  },
  data() {
    return {
      User,
      groupList: [],
      selected: [],
      loading: true,
      headers: [
        { text: 'ID', value: 'groupUuid' },
        { text: 'NAME', value: 'groupName' },

        // { text: 'CREATED', value: 'tCreated' },
        // { text: 'ACTIONS', value: 'actions', sortable: false },

        // { text: 'APP', value: 'applicationId' },
      ],
      icons,
      addNewModal: false,
      filterEvent: null,
    }
  },
  mounted() {
    onUserLoaded(this.initialise)
    Groups.onLoaded(() => {
      this.applyFilters()
      this.loading = false
    })
    EventBus.$on('groupCreated', () => {
      this.loading = true
      this.addNewModal = false
    })
    EventBus.$on('adminGroupListFilter', filterEvent => {
      this.filterEvent = filterEvent
      this.applyFilters()
    })
  },
  methods: {
    initialise() {
      // nothing to do?
    },
    applyFilters() {
      this.groupList = Groups.getAll().filter(group => {
        if (this.filterEvent?.groupSearch) {
          const searchRegex = new RegExp(escapeRegExp(this.filterEvent.groupSearch), 'i')
          if (group?.groupName.match(searchRegex)) {
            return true
          }
          if (group?.groupUuid.match(searchRegex)) {
            return true
          }

          return false
        }

        return true
      })
    },
  },
}
</script>
