<template>
  <v-card class="mb-5">
    <v-card-title> Create a New Organisation </v-card-title>
    <v-card-text>Use this form to create a new Organisation.</v-card-text>
    <v-card-text>
      <v-form ref="form" @submit.prevent="onSubmitForm">
        <v-text-field v-model="groupName" label="Organisation Name" :rules="groupNameRules"></v-text-field>
        <v-btn type="submit" color="success" class="mr-2 mt-2" :loading="isSubmitting">
          <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
          &nbsp; Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import requiredRule from '@/rules/requiredRule'

export default {
  data() {
    return {
      groupName: '',
      groupNameRules: [requiredRule],
      icons,
      isSubmitting: false,
    }
  },
  methods: {
    async onSubmitForm() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.isSubmitting = true
      const group = {
        groupUuid: uuidv4(),
        groupName: this.groupName,
        applicationId: process.env.VUE_APP_DEFAULT_APPLICATION_ID,

        // tCreated: new Date().toISOString(),
      }
      const response = await UploadsApi.adminPersistGroup(group)
      if (response?.errors) {
        // todo: ui error msg
        // todo: render errors if there are any https://stackoverflow.com/questions/48613643/server-side-form-validation-with-vue-js-and-vuetify
        // if (response.errors?.groupName) {
        //   console.log('submit response.errors?.groupName', response.errors?.groupName)
        // }
      } else {
        // no errors - reset form
        // todo: ui success msg
        this.$refs.form.reset()
        EventBus.$emit('groupCreated', group)
      }
      this.isSubmitting = false
    },

    // openErrorDialog(thisFile) {
    //   this.errorDialogFile = thisFile
    //   this.errorDialog = true
    // },
    // closeErrorDialog() {
    //   this.errorDialog = false
    // },
  },
}
</script>
