import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardTitle,[_vm._v(" Create a New Organisation ")]),_c(VCardText,[_vm._v("Use this form to create a new Organisation.")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Organisation Name","rules":_vm.groupNameRules},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}}),_c(VBtn,{staticClass:"mr-2 mt-2",attrs:{"type":"submit","color":"success","loading":_vm.isSubmitting}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiCheckCircle))]),_vm._v("   Submit ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }