import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VDialog,{attrs:{"width":"80%"},model:{value:(_vm.addNewModal),callback:function ($$v) {_vm.addNewModal=$$v},expression:"addNewModal"}},[_c('AdminCreateGroup')],1),_c(VSheet,{staticClass:"d-flex mb-0"},[_c(VSheet,{staticClass:"mr-auto"},[_c(VCardTitle,[_vm._v(" Organisations List ")]),_c(VCardText,[_vm._v(" Here is the list of all active organisations that you manage. ")])],1),_c(VSheet,{staticClass:"pa-4"},[(_vm.User.isSuperAdmin)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.addNewModal = true}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlusCircle))]),_vm._v("   NEW ORGANISATION ")],1):_vm._e()],1)],1),_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.groupList,"item-key":"groupUuid","show-select":"","items-per-page":30,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 30, 50, 100, -1],
    }},scopedSlots:_vm._u([{key:"item.groupName",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'admin-group', params: { groupUuid: item.groupUuid } }}},[_vm._v(" "+_vm._s(item.groupName)+" ")])]}},{key:"item.actions",fn:function(ref){return [_c('div',{staticClass:"mt-1"})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }