import EventBus from '@/classes/EventBus'
import UploadsApi from '@/classes/UploadsApi'
import User from '@/classes/User'
import onUserLoaded from '@/utils/onUserLoaded'

let groups = []

const GroupsApi = {
  getNameById(id) {
    if (!groups) {
      return 'Loading...'
    }
    const group = groups.find(o => o.groupUuid === id)

    return group?.groupName || '???'
  },
  getAll() {
    return groups
  },

  async refresh() {
    if (User.isAdmin) {
      groups = (await UploadsApi.adminListGroups())?.Items
    } else {
      groups = []
    }
    EventBus.$emit('groupsRefreshed', groups)

    return groups
  },

  onLoaded(func) {
    if (groups.length) {
      func(groups)
    }

    EventBus.$on('groupsRefreshed', func)
  },
}

// Always load groups
onUserLoaded(() => {
  GroupsApi.refresh()
})

EventBus.$on('groupCreated', () => {
  GroupsApi.refresh()
})
EventBus.$on('groupEdited', () => {
  GroupsApi.refresh()
})

export default GroupsApi
